import { createPostComment } from '@/services/posts.service';
import { CreateCommentPayloadType, PostType } from '@/types';
import { InvalidateQueryFilters, useMutation, useQueryClient } from '@tanstack/react-query';

const useCreatePostCommentMutation = (dataKey: string) => {
  const queryClient = useQueryClient();
  const allQueryKeys = queryClient
    .getQueryCache()
    .getAll()
    .map((query) => query.queryKey);

  const foundKeys = allQueryKeys.filter(([key]) => key === dataKey) ?? [];

  return useMutation({
    mutationFn: (payload: CreateCommentPayloadType) => createPostComment(payload),

    onMutate: async (payload) => {
      foundKeys.forEach(async (queryKey) => await queryClient.cancelQueries({ queryKey }));
      const previousPosts = foundKeys.map((queryKey) => queryClient.getQueryData(queryKey));

      foundKeys.forEach((queryKey) =>
        queryClient.setQueryData<any>(queryKey, (old: any) => {
          if (!old) return old;
          const newOldPages = old.pages?.map((page: any) => ({
            ...page,
            data: page?.data?.map((post: PostType) =>
              post.id === payload.contentId
                ? { ...post, commentCount: post.commentCount + 1 }
                : post
            ),
          }));
          return { ...old, pages: newOldPages };
        })
      );

      return { previousPosts };
    },

    // On Success Callback is being handled in the component itself
    onSuccess: (res, variables, context) => {
      foundKeys.forEach((queryKey) =>
        queryClient.invalidateQueries(queryKey as InvalidateQueryFilters)
      );
    },

    onError: (res, variables, context) => {
      // Rollback the optimistic update if mutation fails
      if (context?.previousPosts) {
        foundKeys.forEach((queryKey) =>
          queryClient.setQueryData(queryKey, context.previousPosts as any)
        );
      }
    },
  });
};

export default useCreatePostCommentMutation;
