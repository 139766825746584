import { cn, updatedParseMentions } from '@/lib/utils';
import Link from 'next/link';
import React from 'react';

const MentionText: React.FC<{ text: string; className?: string }> = ({ text, className }) => {
  const parsedText = updatedParseMentions(text);

  return (
    <div
      className={cn(
        'mb-2 text-xs text-gray-brand7 whitespace-pre-line break-words prose',
        className
      )}
    >
      {parsedText.map((part, index) => {
        if (typeof part === 'string') {
          // ✅ Render normal text
          return <React.Fragment key={index}>{part}</React.Fragment>;
        } else if ('id' in part) {
          // ✅ Render mention (IMention)
          return (
            <Link
              href={`/profile/${part.id}`}
              key={index}
              className="text-orange-brand2 cursor-pointer hover:underline"
            >
              @{part.name}
            </Link>
          );
        } else if ('url' in part) {
          // ✅ Render an empty div for ILink
          return (
            <Link
              href={part.url}
              key={index}
              className="text-blue-500 cursor-pointer underline hover:italic"
              target="_blank"
              rel="noopener noreferrer"
            >
              {part.url}
            </Link>
          );
        }

        return null; // Fallback case (should never hit)
      })}
    </div>
  );
};

export default MentionText;
