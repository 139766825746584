import { toggleLike } from '@/services/posts.service';
import { PostType, ToggleInputType } from '@/types';
import { InvalidateQueryFilters, useMutation, useQueryClient } from '@tanstack/react-query';

const useToggleLikeMutation = (dataKey: string) => {
  // const { toast } = useToast();
  const queryClient = useQueryClient();
  const allQueryKeys = queryClient
    .getQueryCache()
    .getAll()
    .map((query) => query.queryKey);

  const foundKeys = allQueryKeys.filter(([key]) => key === dataKey) ?? [];

  return useMutation({
    mutationFn: ({ contentId, parentType, like }: ToggleInputType) =>
      toggleLike({ contentId, parentType, like }),

    onMutate: async ({ contentId, like }) => {
      foundKeys.forEach(async (queryKey) => await queryClient.cancelQueries({ queryKey }));

      const previousPosts = foundKeys.map((queryKey) => queryClient.getQueryData(queryKey));

      foundKeys.forEach((queryKey) =>
        queryClient.setQueryData<any>(queryKey, (old: any) => {
          if (!old) return old;
          const newOldPages = old.pages?.map((page: any) => ({
            ...page,
            data: page?.data?.map((post: PostType) => {
              return post.id === contentId
                ? { ...post, likeCount: post.likeCount + (!like ? -1 : 1), isLiked: like }
                : post;
            }),
          }));

          return { ...old, pages: newOldPages };
        })
      );

      // Return a context object with the snapshotted value
      return { previousPosts };
    },

    onSuccess: (res, variables, context) => {
      foundKeys.forEach((queryKey) =>
        queryClient.invalidateQueries(queryKey as InvalidateQueryFilters)
      );
    },

    onError: (res, variables, context) => {
      // Rollback the optimistic update if mutation fails
      if (context?.previousPosts) {
        foundKeys.forEach((queryKey) =>
          queryClient.setQueryData(queryKey, context.previousPosts as any)
        );
      }
      // const errorString: unknown = res;
      // toast({
      //   variant: 'destructive',
      //   description: errorString as string,
      // });
    },
  });
};

export default useToggleLikeMutation;
