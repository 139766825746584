const CommentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.08464 15.8337H6.66797C3.33464 15.8337 1.66797 15.0003 1.66797 10.8337V6.66699C1.66797 3.33366 3.33464 1.66699 6.66797 1.66699H13.3346C16.668 1.66699 18.3346 3.33366 18.3346 6.66699V10.8337C18.3346 14.167 16.668 15.8337 13.3346 15.8337H12.918C12.6596 15.8337 12.4096 15.9587 12.2513 16.167L11.0013 17.8337C10.4513 18.567 9.5513 18.567 9.0013 17.8337L7.7513 16.167C7.61797 15.9837 7.30964 15.8337 7.08464 15.8337Z"
      stroke="#7F7186"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83203 6.66699H14.1654"
      stroke="#7F7186"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83203 10.833H10.832"
      stroke="#7F7186"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CommentIcon;
