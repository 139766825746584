import React from 'react';
import { cn, updatedParseMentions } from '@/lib/utils';
import Link from 'next/link';
import { routePaths } from '@/routes/routePaths';

export const PostContentText = ({
  text,
  className,
}: {
  text: string | null;
  className?: string;
}) => {
  //No Text passed in
  if (!text) return <></>;

  const charLimit = 300;
  const [isExpanded, setIsExpanded] = React.useState(false);

  const hasMoreThanLimit = text.length > charLimit ? true : false;

  //if the text is not long enough to need a read more
  //Just render the text properly using the text parser function.
  if (!hasMoreThanLimit) {
    const parsedText = updatedParseMentions(text);

    return (
      <div
        className={cn(
          'mb-2 text-sm text-gray-brand7 whitespace-pre-line break-words prose',
          className
        )}
      >
        {parsedText.map((part, index) => {
          if (typeof part === 'string') {
            // ✅ Render normal text
            return <React.Fragment key={index}>{part}</React.Fragment>;
          } else if ('id' in part) {
            // ✅ Render mention (IMention)
            return (
              <Link
                href={`${routePaths.people}/${part.name}`}
                key={index}
                className="text-orange-brand2 cursor-pointer hover:underline"
              >
                @{part.name}
              </Link>
            );
          } else if ('url' in part) {
            // ✅ Render an empty div for ILink
            return (
              <Link
                href={part.url}
                key={index}
                className="text-blue-500 cursor-pointer underline hover:italic"
                target="_blank"
                rel="noopener noreferrer"
              >
                {part.url}
              </Link>
            );
          }

          return null; // Fallback case (should never hit)
        })}
      </div>
    );
  }

  // if the text is long enough and not expeanded
  //then just show the shortened text by stripping the text and adding teh "..."
  if (hasMoreThanLimit && !isExpanded) {
    const shortenedText = `${text.slice(0, charLimit)}`;
    const parsedShortenedText = updatedParseMentions(shortenedText);

    return (
      <div
        className={cn(
          'mb-2 text-sm text-gray-brand7 whitespace-pre-line break-words prose',
          className
        )}
      >
        {parsedShortenedText.map((part, index) => {
          if (typeof part === 'string') {
            // ✅ Render normal text
            return <React.Fragment key={index}>{part}</React.Fragment>;
          } else if ('id' in part) {
            // ✅ Render mention (IMention)
            return (
              <Link
                href={`${routePaths.people}/${part.name}`}
                key={index}
                className="text-orange-brand2 cursor-pointer hover:underline"
              >
                @{part.name}
              </Link>
            );
          } else if ('url' in part) {
            // ✅ Render an empty div for ILink
            return (
              <Link
                href={part.url}
                key={index}
                className="text-blue-500 cursor-pointer underline hover:italic"
                target="_blank"
                rel="noopener noreferrer"
              >
                {part.url}
              </Link>
            );
          }

          return null; // Fallback case (should never hit)
        })}

        <button
          className="text-purple-brand outline-none font-semibold"
          onClick={() => setIsExpanded(true)}
        >
          ... Show More
        </button>
      </div>
    );
  }

  //if the text is long enough and is expanded, then show the full thing using the parser function
  //and add the show less button at the end
  const parsedText = updatedParseMentions(text);

  return (
    <div
      className={cn(
        'mb-2 text-sm text-gray-brand7 whitespace-pre-line break-words prose',
        className
      )}
    >
      {parsedText.map((part, index) => {
        if (typeof part === 'string') {
          // ✅ Render normal text
          return <React.Fragment key={index}>{part}</React.Fragment>;
        } else if ('id' in part) {
          // ✅ Render mention (IMention)
          return (
            <Link
              href={`${routePaths.people}/${part.name}`}
              key={index}
              className="text-orange-brand2 cursor-pointer hover:underline"
            >
              @{part.name}
            </Link>
          );
        } else if ('url' in part) {
          // ✅ Render an empty div for ILink
          return (
            <Link
              href={part.url}
              key={index}
              className="text-blue-500 cursor-pointer underline hover:italic"
              target="_blank"
              rel="noopener noreferrer"
            >
              {part.url}
            </Link>
          );
        }

        return null; // Fallback case (should never hit)
      })}

      <button
        className="text-purple-brand outline-none font-semibold block"
        onClick={() => setIsExpanded(false)}
      >
        Show Less
      </button>
    </div>
  );
};
