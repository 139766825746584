const HeartIcon = ({ color, filled }: { color?: string; filled?: boolean }) =>
  filled ? (
    <svg width="20" height="20" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_646_67232)">
        <path
          d="M16.7013 3.58301C15.193 3.58301 13.843 4.31634 13.0013 5.44134C12.1596 4.31634 10.8096 3.58301 9.3013 3.58301C6.74297 3.58301 4.66797 5.66634 4.66797 8.24134C4.66797 9.23301 4.8263 10.1497 5.1013 10.9997C6.41797 15.1663 10.4763 17.658 12.4846 18.3413C12.768 18.4413 13.2346 18.4413 13.518 18.3413C15.5263 17.658 19.5846 15.1663 20.9013 10.9997C21.1763 10.1497 21.3346 9.23301 21.3346 8.24134C21.3346 5.66634 19.2596 3.58301 16.7013 3.58301Z"
          fill={color ?? '#FF951C'}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_646_67232"
          x="-1"
          y="-2"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.00784314 0 0 0 0 0.0784314 0 0 0 0.1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_646_67232" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_646_67232"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.518 17.3413C10.2346 17.4413 9.76797 17.4413 9.48464 17.3413C7.06797 16.5163 1.66797 13.0747 1.66797 7.24134C1.66797 4.66634 3.74297 2.58301 6.3013 2.58301C7.81797 2.58301 9.15964 3.31634 10.0013 4.44967C10.843 3.31634 12.193 2.58301 13.7013 2.58301C16.2596 2.58301 18.3346 4.66634 18.3346 7.24134C18.3346 13.0747 12.9346 16.5163 10.518 17.3413Z"
        stroke="#7F7186"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export default HeartIcon;
