const EyeIcon = ({ closed }: { closed?: boolean }) =>
  closed ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99995 5.61328C8.27363 5.61328 8.54091 5.65936 8.79276 5.74819C8.54135 5.65972 8.27386 5.61328 7.99995 5.61328ZM7.99995 5.61328C6.68276 5.61328 5.61328 6.68276 5.61328 7.99995M7.99995 5.61328C6.68381 5.61328 5.61328 6.68381 5.61328 7.99995M5.61328 7.99995C5.61328 8.27363 5.65936 8.54091 5.74819 8.79276C5.65972 8.54135 5.61328 8.27386 5.61328 7.99995Z"
        fill="#E6544F"
        stroke="#E6544F"
      />
      <path
        d="M2.035 9.89682C2.46563 10.5429 2.9485 11.1198 3.47768 11.6145C2.94961 11.1264 2.46497 10.551 2.035 9.89682Z"
        fill="#E6544F"
        stroke="#E6544F"
      />
      <path
        d="M7.99636 13.5137C10.3617 13.5123 12.5621 12.0922 14.0677 9.72958C14.3828 9.23762 14.5214 8.6015 14.5214 8.00032C14.5214 7.39942 14.383 6.76361 14.0681 6.27173C13.8513 5.92909 13.6137 5.60058 13.362 5.29284C13.3622 5.29175 13.3626 5.29035 13.3634 5.28883C13.3635 5.28855 13.3637 5.28824 13.3639 5.28791C13.3645 5.28844 13.3652 5.28921 13.3661 5.29028L13.753 4.97366M7.99636 13.5137L5.79972 12.5603C6.50638 12.8603 7.24638 13.0137 7.99305 13.0137C10.1464 13.0137 12.2064 11.7203 13.6464 9.46032C14.1464 8.68032 14.1464 7.32032 13.6464 6.54032C13.4397 6.21366 13.213 5.90032 12.973 5.60699M7.99636 13.5137C7.99748 13.5137 7.9986 13.5137 7.99972 13.5137C10.2909 13.5137 12.5142 12.1694 14.0716 9.73123M7.99636 13.5137L14.0716 9.73123M13.753 4.97366C14.013 5.29366 14.2664 5.64032 14.493 6.00032C15.1997 7.10032 15.1997 8.89366 14.493 10.0003M13.753 4.97366L13.365 5.28895C13.6141 5.59556 13.8554 5.92594 14.0699 6.26673L14.0699 6.26674L14.0724 6.27057C14.3628 6.72258 14.5231 7.3465 14.5231 7.99782C14.5231 8.64899 14.3628 9.2752 14.0717 9.73117L14.493 10.0003M13.753 4.97366C13.573 4.75366 13.2597 4.72699 13.0464 4.90032M14.493 10.0003L14.0716 9.73123M14.493 10.0003C12.8664 12.547 10.4997 14.0137 7.99972 14.0137C7.11305 14.0137 6.24638 13.8337 5.41305 13.4803L14.0716 9.73123M13.0464 4.90032C12.833 5.08032 12.7997 5.39366 12.973 5.60699M13.0464 4.90032L13.3617 5.28838C13.3624 5.28781 13.3629 5.28747 13.3632 5.28729L13.0464 4.90032ZM12.973 5.60699L13.3611 5.29171L12.973 5.60699Z"
        fill="#E6544F"
        stroke="#E6544F"
      />
      <path
        d="M8.45973 10.8469C8.22639 10.8469 8.01306 10.6802 7.96639 10.4402C7.91306 10.1669 8.09306 9.9069 8.36639 9.86023C9.09973 9.7269 9.71306 9.11356 9.8464 8.38023C9.89973 8.1069 10.1597 7.93356 10.4331 7.98023C10.7064 8.03356 10.8864 8.29356 10.8331 8.5669C10.6197 9.72023 9.69973 10.6336 8.55306 10.8469C8.51973 10.8402 8.49306 10.8469 8.45973 10.8469Z"
        fill="#E6544F"
      />
      <path
        d="M1.33329 15.1668C1.20663 15.1668 1.07996 15.1201 0.979961 15.0201C0.786628 14.8268 0.786628 14.5068 0.979961 14.3135L5.95996 9.33348C6.15329 9.14014 6.47329 9.14014 6.66663 9.33348C6.85996 9.52681 6.85996 9.84681 6.66663 10.0401L1.68663 15.0201C1.58663 15.1201 1.45996 15.1668 1.33329 15.1668Z"
        fill="#E6544F"
      />
      <path
        d="M9.68681 6.81329C9.56014 6.81329 9.43348 6.76663 9.33348 6.66663C9.14014 6.47329 9.14014 6.15329 9.33348 5.95996L14.3135 0.979961C14.5068 0.786628 14.8268 0.786628 15.0201 0.979961C15.2135 1.17329 15.2135 1.49329 15.0201 1.68663L10.0401 6.66663C9.94014 6.76663 9.81348 6.81329 9.68681 6.81329Z"
        fill="#E6544F"
      />
    </svg>
  ) : (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9823 9.99993C12.9823 11.6499 11.649 12.9833 9.99896 12.9833C8.34896 12.9833 7.01562 11.6499 7.01562 9.99993C7.01562 8.34993 8.34896 7.0166 9.99896 7.0166C11.649 7.0166 12.9823 8.34993 12.9823 9.99993Z"
        stroke="#7F7186"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99792 16.8913C12.9396 16.8913 15.6813 15.1579 17.5896 12.1579C18.3396 10.9829 18.3396 9.00794 17.5896 7.83294C15.6813 4.83294 12.9396 3.09961 9.99792 3.09961C7.05625 3.09961 4.31458 4.83294 2.40625 7.83294C1.65625 9.00794 1.65625 10.9829 2.40625 12.1579C4.31458 15.1579 7.05625 16.8913 9.99792 16.8913Z"
        stroke="#7F7186"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export default EyeIcon;
