const SaveIcon = ({ selected }: { selected?: boolean }) =>
  selected ? (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M10.743 4.90039H4.25962C2.83462 4.90039 1.66797 6.06704 1.66797 7.49204V16.9587C1.66797 18.1671 2.53463 18.6837 3.59297 18.092L6.86796 16.267C7.21796 16.0754 7.78464 16.0754 8.1263 16.267L11.4013 18.092C12.4596 18.6837 13.3263 18.1671 13.3263 16.9587V7.49204C13.3346 6.06704 12.168 4.90039 10.743 4.90039Z"
        fill="#685B6E"
      />
      <path
        d="M18.3346 4.25865V13.7253C18.3346 14.9337 17.468 15.442 16.4096 14.8586L13.3346 13.142V7.49198C13.3346 6.06698 12.168 4.90033 10.743 4.90033H6.66797V4.25865C6.66797 2.83365 7.83462 1.66699 9.25962 1.66699H15.743C17.168 1.66699 18.3346 2.83365 18.3346 4.25865Z"
        fill="#685B6E"
      />
    </svg>
  ) : (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.89 5.87988H5.10999C3.39999 5.87988 2 7.27987 2 8.98987V20.3499C2 21.7999 3.04 22.4199 4.31 21.7099L8.23999 19.5199C8.65999 19.2899 9.34 19.2899 9.75 19.5199L13.68 21.7099C14.95 22.4199 15.99 21.7999 15.99 20.3499V8.98987C16 7.27987 14.6 5.87988 12.89 5.87988Z"
        stroke="#7F7186"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 8.98987V20.3499C16 21.7999 14.96 22.4099 13.69 21.7099L9.76001 19.5199C9.34001 19.2899 8.65999 19.2899 8.23999 19.5199L4.31 21.7099C3.04 22.4099 2 21.7999 2 20.3499V8.98987C2 7.27987 3.39999 5.87988 5.10999 5.87988H12.89C14.6 5.87988 16 7.27987 16 8.98987Z"
        stroke="#7F7186"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 5.10999V16.47C22 17.92 20.96 18.53 19.69 17.83L16 15.77V8.98999C16 7.27999 14.6 5.88 12.89 5.88H8V5.10999C8 3.39999 9.39999 2 11.11 2H18.89C20.6 2 22 3.39999 22 5.10999Z"
        stroke="#7F7186"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export default SaveIcon;
